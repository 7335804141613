const React = require('react');

const { Message } = require('@andes/message');

const Cookie = require('../../../../services/utils/cookie');

const QaMessage = () => {
  const cookieName = 'meliLab';
  const versionName = 'Otimização de cadastro de revendedores';
  const cookie = Cookie.getCookie(cookieName);

  return (
    !!cookie && (
      <section className="p-16 relative-item z-index-2">
        <Message actionsPosition="bottom" color="accent">
          Estás en ambiente: <b>{cookie}</b> - Con la version: <b>{versionName}</b>
        </Message>
      </section>
    )
  );
};

module.exports = QaMessage;
